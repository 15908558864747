import evento from "../assets/Blog/Alex-Evento.jpg";
import imgPost3 from "../assets/Blog/imgPost3.webp";
import imgPost1 from "../assets/Blog/imgPost1.webp";
import imgPost4 from "./image_carrossel_gota.png";
import imgPost5 from "../assets/Blog/imgPost5.jpg";
import imgPost6 from "../assets/Blog/imagPost6.jpeg";
import imgPost7Section from "../assets/Blog/máquina.jfif";
import imgPost7 from "../assets/Components/PopUp/backgroundSemBlur.jpg";
import imgPost8 from "../assets/Blog/post8.webp";
import imgPost9 from "../assets/Blog/imgPost9.jpg";
import imgPost10 from "../assets/Blog/imgPost10.jpg";
import imgPost11 from "../assets/Blog/imgPost11.png";
import { useTranslation } from "react-i18next";
import imgPost12 from "../assets/Blog/imgPost12.jpg";
import imgPost13 from "../assets/Blog/imgPost13.jpg";
import imgPost14 from "../assets/Blog/imgPost14.jpg";
import imgPost15 from "../assets/Blog/imgpost15.avif";
import imgPost16 from "../assets/Blog/imgPost16.jpg";
import imgPost17 from "../assets/Blog/imgPost17.jpg";
import imgPost18 from "../assets/Blog/imgPost18.jpg";
import imgPost19 from "../assets/Blog/imgPost19.png";
import imgPost20 from "../assets/Blog/imgPost20.jpeg";
const useDataBlog = () => {
  const { t } = useTranslation();

  return [
    {
      _id: "1",
      titleKey: t("blogTituloPost1"), // Chave para tradução
      contentKeys: [t("post1Titulo1")], // Chaves para tradução
      postType: "procedimentos",
      photo: imgPost8,
      publishDate: "2024-08-14",
      relatedPostsIds: ["2", "3"],
      sections: [
        {
          titleKey: t("post1Titulo1"), // Chave para tradução
          subtitleKey: "", // Se não houver subtítulo, deixe vazio ou remova
          contentKeys: [
            t("post1conteudo1"),
            t("post1conteudo2"),
            t("post1conteudo3"),
            t("post1conteudo4"),
            t("post1conteudo5"),
            t("post1conteudo6"),
            t("post1conteudo7"),
          ], // Chaves para tradução
        },
        {
          titleKey: t("post1Titulo2"), // Chave para tradução
          subtitleKey: "",
          contentKeys: [
            t("post1conteudo8"),
            t("post1list1"),
            t("post1list2"),
            t("post1list3"),
            t("post1list4"),
          ], // Chaves para tradução
        },
        {
          titleKey: t("post1Titulo3"), // Chave para tradução
          subtitleKey: t("post1conteudo8"), // Chave para tradução
          contentKeys: [t("post1list5"), t("post1list6"), t("post1list7")], // Chaves para tradução
        },
        {
          titleKey: "",
          subtitleKey: t("post1conteudo10"), // Chave para tradução
          contentKeys: [t("post1conteudo11"), t("post1list8"), t("post1list9")], // Chaves para tradução
        },
        {
          titleKey: t("post1conteudo14"), // Chave para tradução
          subtitleKey: "",
          contentKeys: [t("post1conteudo15")], // Chaves para tradução
        },
      ],
    },
    {
      _id: "2",
      titleKey: t("post2Title1"), // Chave para tradução
      contentKeys: [t("post2subtitle1")], // Chaves para tradução
      postType: "noticias",
      photo: evento,
      publishDate: "2024-08-17",
      relatedPostsIds: ["1", "3"],
      sections: [
        {
          titleKey: t("post2subtitle1"), // Chave para tradução
          contentKeys: [t("post2conteudo1")], // Chaves para tradução
        },
        {
          subtitleKey: t("post2subtitle2"), // Chave para tradução
          contentKeys: [t("post2conteudo2")], // Chaves para tradução
        },
        {
          subtitleKey: t("post2subtitle3"), // Chave para tradução
          contentKeys: [t("post2conteudo3")], // Chaves para tradução
        },
        {
          subtitleKey: t("post2subtitle4"), // Chave para tradução
          contentKeys: [t("post2conteudo4"), t("post2conteudo5")], // Chaves para tradução
        },
      ],
    },
    {
      _id: "3",
      titleKey: t("post3Title1"), // Chave para tradução
      contentKeys: [t("post3subtitle1")], // Chaves para tradução
      postType: "consejos",
      photo: imgPost3,
      publishDate: "2024-09-02",
      relatedPostsIds: ["1", "2"],
      sections: [
        {
          titleKey: t("post3section1"), // Chave para tradução
          contentKeys: [t("post3conteudo1")], // Chaves para tradução
        },
        {
          subtitleKey: t("post3section2"), // Chave para tradução
          contentKeys: [t("post3conteudo2")], // Chaves para tradução
        },
        {
          subtitleKey: t("post3section3"), // Chave para tradução
          contentKeys: [t("post3conteudo3")], // Chaves para tradução
        },
        {
          subtitleKey: t("post3section4"), // Chave para tradução
          contentKeys: [t("post3conteudo4")], // Chaves para tradução
        },
        {
          subtitleKey: t("post3section5"), // Chave para tradução
          contentKeys: [t("post3conteudo5")], // Chaves para tradução
        },
        {
          subtitleKey: t("post3section6"), // Chave para tradução
          contentKeys: [t("post3conteudo6"), t("post3conteudo7")], // Chaves para tradução
        },
      ],
    },
    {
      _id: "4",
      titleKey: t("post4Title1"), // Chave para tradução
      contentKeys: [t("post4subtitle1")], // Chaves para tradução
      postType: "consejos",
      photo: imgPost4,
      publishDate: "2024-09-02",
      relatedPostsIds: ["1", "2", "3"],
      sections: [
        {
          titleKey: t("post4section1"), // Chave para tradução
          contentKeys: [t("post4conteudo1")], // Chaves para tradução
        },
        {
          titleKey: t("post4section2"), // Chave para tradução
          contentKeys: [t("post4conteudo2")], // Chaves para tradução
        },
        {
          titleKey: t("post4section3"),
          subtitleKey: t("post4conteudo3"), // Chave para tradução
          contentKeys: [
            t("post4conteudo4"),
            t("post4conteudo5"),
            t("post4conteudo6"),
            t("post4conteudo7"),
            t("post4conteudo8"),
          ], // Chaves para tradução
        },
        {
          titleKey: t("post4section4"),
          subtitleKey: t("post4conteudo9"),
          contentKeys: [
            t("post4conteudo10"),
            t("post4conteudo11"),
            t("post4conteudo12"),
            t("post4conteudo13"),
          ], // Chaves para tradução
        },
        {
          titleKey: t("post4section5"), // Chave para tradução
          contentKeys: [
            t("post4conteudo14"),
            t("post4conteudo15"),
            t("post4conteudo16"),
            t("post4conteudo17"),
            t("post4conteudo18"),
            t("post4conteudo19"),
            t("post4conteudo20"),
          ],
        },
        {
          titleKey: t("post4section6"), // Chave para tradução
          contentKeys: [t("post3conteudo21")], // Chaves para tradução
        },
        {
          contentKeys: [""],
          subtitleKey: t("post4conteudo22"),
        },
      ],
    },
    {
      _id: "5",
      titleKey: t("post5title"), // Chave para tradução
      contentKeys: [t("post5subtitle")], // Chaves para tradução
      postType: "praticas",
      photo: imgPost5,
      publishDate: "2024-09-28",
      relatedPostsIds: ["1", "2", "3"],
      sections: [
        {
          titleKey: t("post5section1"), // Chave para tradução
          contentKeys: [t("post5conteudo1")], // Chaves para tradução
        },
        {
          titleKey: t("post5section2"),
          subtitleKey: t("post5subtitle1"), // Chave para tradução
          contentKeys: [t("post5conteudo2")], // Chaves para tradução
        },
        {
          subtitleKey: t("post5subtitle2"), // Chave para tradução
          contentKeys: [t("post5conteudo3")], // Chaves para tradução
        },
        {
          subtitleKey: t("post5subtitle3"),
          contentKeys: [t("post5conteudo4")], // Chaves para tradução
        },
        {
          subtitleKey: t("post5subtitle4"),
          contentKeys: [t("post5conteudo5")], // Chaves para tradução
        },
        {
          titleKey: t("post5title2"),
          subtitleKey: t("post5subtitle5"), // Chave para tradução
          contentKeys: [t("post5conteudo6")], // Chaves para tradução
        },
        {
          titleKey: t("post5title3"),
          contentKeys: [t("post5conteudo70")], // Chaves para tradução
        },
      ],
    },
    {
      _id: "6",
      titleKey: t("post6Title"), // Chave para tradução
      contentKeys: [t("post6subtitle")], // Chaves para tradução
      postType: "tendencias",
      photo: imgPost6,
      publishDate: "2024-09-28",
      relatedPostsIds: ["1", "2", "3"],
      sections: [
        {
          titleKey: t("post6section1"), // Chave para tradução
          contentKeys: [t("post6conteudo1")], // Chaves para tradução
        },
        {
          titleKey: t("post6section2"),
          subtitleKey: t("post6subtitle1"), // Chave para tradução
          contentKeys: [t("post6conteudo2")], // Chaves para tradução
        },
        {
          titleKey: t("post6section2"), // Adicionado
          contentKeys: [t("post6conteudo5")], // Chaves para tradução
        },
        {
          subtitleKey: t("post6subtitle2"), // Adicionado
          contentKeys: [t("post6conteudo6"), t("post6conteudo7")], // Chaves para tradução
        },
        {
          titleKey: t("post6section3"),
          contentKeys: [t("post6conteudo8")],
        },
        {
          subtitleKey: t("post6subtitle3"), // Adicionado
          contentKeys: [t("post6conteudo9"), t("post6conteudo10")], // Chaves para tradução
        },
        {
          titleKey: t("post6section4"), // Chave para tradução
          contentKeys: [t("post6conteudo11")], // Chaves para tradução
        },
        {
          subtitleKey: t("post6subtitle4"), // Adicionado
          contentKeys: [t("post6conteudo12"), t("post6conteudo13")], // Chaves para tradução
        },
        {
          titleKey: t("post6section5"), // Chave para tradução
          contentKeys: [t("post6conteudo14")], // Chaves para tradução
        },
        {
          subtitleKey: t("post6subtitle5"), // Adicionado
          contentKeys: [t("post6conteudo15"), t("post6conteudo16")], // Chaves para tradução
        },
        {
          titleKey: t("post6section6"), // Chave para tradução
          contentKeys: [t("post6conteudo17")], // Chaves para tradução
        },
        {
          subtitleKey: t("post6subtitle6"), // Adicionado
          contentKeys: [t("post6conteudo18"), t("post6conteudo19")], // Chaves para tradução
        },
        {
          contentKeys: [t("post6conteudo20"), t("post6conteudo21")],
        },
      ],
    },
    {
      _id: "7",
      titleKey: t("post7Title"), // Chave para tradução
      contentKeys: [t("post7subtitle")], // Chaves para tradução
      postType: "procedimentos",
      photo: imgPost7,
      publishDate: "2024-10-28",
      relatedPostsIds: ["1", "2", "3"],
      sections: [
        {
          contentKeys: [t("post7conteudo1")], // Chaves para tradução
        },
        {
          titleKey: t("post7section1"),
          contentKeys: [t("post7conteudo2")], // Chaves para tradução
        },
        {
          titleKey: t("post7section2"),
          subtitleKey: t("post7subtitle1"), // Chave para tradução
          contentKeys: [
            t("post7conteudo3"),
            t("post7conteudo4"),
            t("post7conteudo5"),
            t("post7conteudo6"),
          ], // Chaves para tradução
        },
        {
          titleKey: t("post7section3"),
          subtitleKey: t("post7subtitle2"), // Chave para tradução
          contentKeys: [
            t("post7conteudo7"),
            t("post7conteudo8"),
            t("post7conteudo9"),
            t("post7conteudo10"),
          ], // Chaves para tradução
        },
        {
          titleKey: t("post7section4"), // Chave para tradução
          contentKeys: [t("post7conteudo11")], // Chaves para tradução
        },
        {
          titleKey: t("post7section5"),
          contentKeys: [t("post7conteudo12")],
        },
        {
          titleKey: t("post7section6"),
          subtitleKey: t("post7subtitle3"),
          contentKeys: [
            t("post7conteudo13"),
            t("post7conteudo14"),
            t("post7conteudo15"),
            t("post7conteudo16"),
            t("post7conteudo17"),
            t("post7conteudo18"),
          ], // Chaves para tradução
        },
        {
          titleKey: t("post7section7"), // Chave para tradução
          contentKeys: [t("post7conteudo19"), t("post7conteudo20")], // Chaves para tradução
        },
        {
          titleKey: t("post7section8"),
          contentKeys: [t("post7conteudo21")],
          image: imgPost7Section,
        },
        {
          titleKey: t("post7section9"),
          subtitleKey: t("post7subtitle4"),
          contentKeys: [
            t("post7conteudo22"),
            t("post7conteudo23"),
            t("post7conteudo24"),
          ],
        },
        {
          titleKey: t("post7section10"),
          subtitleKey: t("post7subtitle5"),
          contentKeys: [
            t("post7conteudo25"),
            t("post7conteudo26"),
            t("post7conteudo27"),
          ],
        },
        {
          titleKey: t("post7section11"),

          contentKeys: [t("post7conteudo28"), t("post7conteudo29")],
        },
      ],
    },
    {
      _id: "8",
      titleKey: t("post8Title"), // Chave para tradução
      contentKeys: [t("post8subtitle")], // Chaves para tradução
      postType: "procedimentos",
      photo: imgPost1,
      publishDate: "2024-11-03",
      relatedPostsIds: ["1", "2", "3"],
      sections: [
        {
          contentKeys: [t("post8conteudo1")], // Chaves para tradução
        },
        {
          titleKey: t("post8section1"),
          contentKeys: [t("post8conteudo2")], // Chaves para tradução
        },
        {
          titleKey: t("post8section2"),

          contentKeys: [t("post8conteudo3")], // Chaves para tradução
        },
        {
          titleKey: t("post8section3"),
          subtitleKey: t("post8subtitle1"), // Chave para tradução
          contentKeys: [
            t("post8conteudo4"),
            t("post8conteudo5"),
            t("post8conteudo6"),
          ], // Chaves para tradução
        },
        {
          titleKey: t("post8section4"),
          subtitleKey: t("post8subtitle2"), // Chave para tradução
          contentKeys: [
            t("post8conteudo7"),
            t("post8conteudo8"),
            t("post8conteudo9"),
            t("post8conteudo10"),
          ], //  // Chaves para tradução
        },
        {
          titleKey: t("post8section5"),
          contentKeys: [t("post8conteudo11")],
        },
        {
          titleKey: t("post8section6"),

          contentKeys: [t("post8conteudo12"), t("post8conteudo13")], // Chaves para tradução
        },
      ],
    },
    {
      _id: "9",
      titleKey: t("post9Title"), // Chave para tradução
      contentKeys: [t("post9subtitle")], // Chaves para tradução
      postType: "procedimentos",
      photo: imgPost9,
      publishDate: "2024-11-12",
      relatedPostsIds: ["1", "2", "3"],
      sections: [
        {
          contentKeys: [t("post9conteudo1")], // Chaves para tradução
        },
        {
          titleKey: t("post9section1"),
          contentKeys: [t("post9conteudo2")], // Chaves para tradução
        },
        {
          titleKey: t("post9section2"),
          subtitleKey: t("post9subtitle1"),
          contentKeys: [t("post9conteudo3")], // Chaves para tradução
        },
        {
          subtitleKey: t("post9subtitle2"),
          contentKeys: [t("post9conteudo4")], // Chaves para tradução
        },
        {
          subtitleKey: t("post9subtitle3"),
          contentKeys: [t("post9conteudo5")], // Chaves para tradução
        },
        {
          subtitleKey: t("post9subtitle4"),
          contentKeys: [t("post9conteudo6")], // Chaves para tradução
        },
        {
          subtitleKey: t("post9subtitle5"),
          contentKeys: [t("post9conteudo7")], // Chaves para tradução
        },
        {
          titleKey: t("post9section3"),
          subtitleKey: t("post9subtitle6"), // Chave para tradução
          contentKeys: [
            t("post9conteudo8"),
            t("post9conteudo9"),
            t("post9conteudo10"),
          ], // Chaves para tradução
        },

        {
          titleKey: t("post9section4"),

          contentKeys: [t("post9conteudo11")], // Chaves para tradução
        },
      ],
    },
    {
      _id: "10",
      titleKey: t("post10Title"), // Chave para tradução
      contentKeys: [t("post10subtitle")], // Chaves para tradução
      postType: "consejos",
      photo: imgPost10,
      publishDate: "2024-11-23",
      relatedPostsIds: ["7", "8", "9"],
      sections: [
        {
          titleKey: t("post10section1"),
          contentKeys: [t("post10conteudo1")], // Chaves para tradução
        },
        {
          titleKey: t("post10section2"),
          contentKeys: [t("post10conteudo2")], // Chaves para tradução
        },
        {
          titleKey: t("post10section3"),
          subtitleKey: t("post10subtitle1"),
          contentKeys: [t("post10conteudo3"), t("post10conteudo4")], // Chaves para tradução
        },
        {
          titleKey: t("post10section4"),
          subtitleKey: t("post10subtitle2"),
          contentKeys: [t("post10conteudo5"), t("post10conteudo6")], // Chaves para tradução
        },
        {
          titleKey: t("post10section5"),
          subtitleKey: t("post10subtitle3"),
          contentKeys: [t("post10conteudo7"), t("post10conteudo8")], // Chaves para tradução
        },
        {
          titleKey: t("post10section6"),
          subtitleKey: t("post10subtitle4"),
          contentKeys: [t("post10conteudo9"), t("post10conteudo10")], // Chaves para tradução
        },
        {
          titleKey: t("post10section7"),
          subtitleKey: t("post10subtitle5"),
          contentKeys: [t("post10conteudo11"), t("post10conteudo12")], // Chaves para tradução
        },
        {
          titleKey: t("post10section8"),

          contentKeys: [
            t("post10conteudo13"),
            t("post10conteudo14"),
            t("post10conteudo15"),
          ], // Chaves para tradução
        },
        {
          titleKey: t("post10section9"),

          contentKeys: [t("post10conteudo16")],
        },
      ],
    },
    {
      _id: "12",
      titleKey: t("post12Title"), // Chave para tradução
      contentKeys: [], // Chaves para tradução
      postType: "masculino",
      photo: imgPost12,
      publishDate: "2024-12-01",
      relatedPostsIds: ["9", "10", "11"],
      sections: [
        {
          contentKeys: [t("post12conteudo1")], // Chaves para tradução
        },
        {
          titleKey: t("post12section1"),
          contentKeys: [t("post12conteudo2")], // Chaves para tradução
        },
        {
          subtitleKey: t("post12subtitle1"),

          contentKeys: [
            t("post12conteudo3"),
            t("post12conteudo4"),
            t("post12conteudo5"),
          ], // Chaves para tradução
        },
        {
          titleKey: t("post12section2"),
          contentKeys: [t("post12conteudo6")], // Chaves para tradução
        },
        {
          subtitleKey: t("post12subtitle2"),

          contentKeys: [t("post12conteudo7"), t("post12conteudo8")], // Chaves para tradução
        },
        {
          titleKey: t("post12section3"),
          contentKeys: [
            t("post12conteudo9"),
            t("post12conteudo10"),
            t("post12conteudo11"),
          ], // Chaves para tradução
        },
        {
          subtitleKey: t("post12subtitle3"),

          contentKeys: [
            t("post12conteudo12"),
            t("post12conteudo13"),
            t("post12conteudo14"),
          ], // Chaves para tradução
        },
        {
          titleKey: t("post12section4"),
          contentKeys: [t("post12conteudo15")], // Chaves para tradução
        },
        {
          subtitleKey: t("post12subtitle4"),

          contentKeys: [
            t("post12conteudo16"),
            t("post12conteudo17"),
            t("post12conteudo18"),
          ], // Chaves para tradução
        },
        {
          titleKey: t("post12section5"),
          contentKeys: [t("post12conteudo19")], // Chaves para tradução
        },
        {
          subtitleKey: t("post12subtitle5"),

          contentKeys: [
            t("post12conteudo20"),
            t("post12conteudo21"),
            t("post12conteudo22"),
          ], // Chaves para tradução
        },
        {
          titleKey: t("post12section6"),

          contentKeys: [t("post12conteudo23"), t("post12conteudo23")], // Chaves para tradução
        },
      ],
    },
    {
      _id: "11",
      titleKey: t("post11Title"), // Chave para tradução
      contentKeys: [t("post11subtitle")], // Chaves para tradução
      postType: "procedimentos",
      photo: imgPost11,
      publishDate: "2024-11-27",
      relatedPostsIds: ["8", "9", "10"],
      sections: [
        {
          contentKeys: [t("post11conteudo1")], // Chaves para tradução
        },
        {
          titleKey: t("post11section1"),
          contentKeys: [t("post11conteudo2")], // Chaves para tradução
        },
        {
          titleKey: t("post11section2"),

          contentKeys: [
            t("post11conteudo3"),
            t("post11conteudo4"),
            t("post11conteudo5"),
            t("post11conteudo6"),
          ], // Chaves para tradução
        },
        {
          titleKey: t("post11section4"),
          subtitleKey: t("post11subtitle1"),
          contentKeys: [
            t("post11conteudo7"),
            t("post11conteudo8"),
            t("post11conteudo9"),
            t("post11conteudo10"),
          ], // Chaves para tradução
        },
        {
          subtitleKey: t("post11section5"),
          contentKeys: [t("post11subtitle2")],
        },
        {
          subtitleKey: t("post11section6"),
          contentKeys: [t("post11subtitle3")],
        },
        {
          subtitleKey: t("post11section7"),
          contentKeys: [t("post11subtitle4")],
        },
        {
          subtitleKey: t("post11section8"),
          contentKeys: [t("post11subtitle5")],
        },
        {
          titleKey: t("post11section9"),
          contentKeys: [t("post11conteudo11")], // Chaves para tradução
        },
        {
          titleKey: t("post11section10"),

          contentKeys: [
            t("post11conteudo12"),
            t("post11conteudo13"),
            t("post11conteudo14"),
          ], // Chaves para tradução
        },
        {
          titleKey: t("post11section11"),
          subtitleKey: t("post11subtitle6"),
          contentKeys: [
            t("post11conteudo15"),
            t("post11conteudo16"),
            t("post11conteudo17"),
          ], // Chaves para tradução
        },
        {
          titleKey: t("post11section12"),
          contentKeys: [t("post11conteudo18")],
        },
      ],
    },
    {
      _id: "13",
      titleKey: t("post13Title"), // Chave para tradução
      contentKeys: [t("post13subtitle")], // Chaves para tradução
      postType: "procedimentos",
      photo: imgPost13,
      publishDate: "2024-12-08",
      relatedPostsIds: ["9", "10", "11"],
      sections: [
        {
          contentKeys: [t("post13conteudo1")], // Chaves para tradução
        },
        {
          titleKey: t("post13section1"),
          contentKeys: [t("post13conteudo2")], // Chaves para tradução
        },
        {
          titleKey: t("post13section2"),
          subtitleKey: t("post13subtitle1"),
          contentKeys: [t("post13conteudo3")], // Chaves para tradução
        },
        {
          subtitleKey: t("post13subtitle2"),
          contentKeys: [t("post13conteudo4")], // Chaves para tradução
        },
        {
          subtitleKey: t("post13subtitle3"),
          contentKeys: [t("post13conteudo5")], // Chaves para tradução
        },
        {
          subtitleKey: t("post13subtitle4"),
          contentKeys: [t("post13conteudo6")], // Chaves para tradução
        },
        {
          subtitleKey: t("post13subtitle5"),
          contentKeys: [t("post13conteudo7")], // Chaves para tradução
        },

        {
          subtitleKey: t("post13section3"),
          contentKeys: [
            t("post13conteudo8"),
            t("post13conteudo9"),
            t("post13conteudo10"),
            t("post13conteudo11"),
          ],
        },

        {
          subtitleKey: t("post13section4"),
          contentKeys: [
            t("post13conteudo12"),
            t("post13conteudo13"),
            t("post13conteudo14"),
            t("post13conteudo15"),
          ],
        },
        {
          titleKey: t("post13section5"),

          contentKeys: [t("post13conteudo16"), t("post13conteudo17")], // Chaves para tradução
        },
      ],
    },
    {
      _id: "14",
      titleKey: t("post14Title"), // Chave para tradução
      contentKeys: [t("post14subtitle")], // Chaves para tradução
      postType: "consejos",
      photo: imgPost14,
      publishDate: "2024-12-17",
      relatedPostsIds: ["11", "12", "13"],
      sections: [
        {
          contentKeys: [t("post14conteudo1")], // Chaves para tradução
        },
        {
          titleKey: t("post14section1"),
          subtitleKey: t("post14subtitle1"),
          contentKeys: [
            t("post14conteudo3"),
            t("post14conteudo4"),
            t("post14conteudo5"),
            t("post14conteudo6"),
          ],
        },
        {
          titleKey: t("post14section2"),
          subtitleKey: t("post14subtitle2"),
          contentKeys: [
            t("post14conteudo7"),
            t("post14conteudo8"),
            t("post14conteudo9"),
            t("post14conteudo10"),
            t("post14conteudo11"),
            t("post14conteudo12"),
          ],
        },
        {
          titleKey: t("post14section3"),
          subtitleKey: t("post14subtitle3"),
          contentKeys: [
            t("post14conteudo13"),
            t("post14conteudo14"),
            t("post14conteudo15"),
            t("post14conteudo16"),
          ],
        },

        {
          titleKey: t("post14section4"),

          contentKeys: [t("post14conteudo17"), t("post14conteudo18")],
        },
      ],
    },
    {
      _id: "15",
      titleKey: t("post15Title"), // Chave para tradução
      contentKeys: [t("post15subtitle")], // Chaves para tradução
      postType: "tendencias",
      photo: imgPost15,
      publishDate: "2025-01-02",
      relatedPostsIds: ["12", "13", "14"],
      sections: [
        {
          contentKeys: [t("post15conteudo1")], // Chaves para tradução
        },
        {
          titleKey: t("post15section1"),

          contentKeys: [t("post15conteudo2")],
        },
        {
          titleKey: t("post15section2"),

          contentKeys: [t("post15conteudo3")],
        },
        {
          titleKey: t("post15section3"),

          contentKeys: [t("post15conteudo4")],
        },
        {
          titleKey: t("post15section4"),
          subtitleKey: t("post15subtitle2"),
          contentKeys: [
            t("post15conteudo5"),
            t("post15conteudo6"),
            t("post15conteudo7"),
          ],
        },
        {
          titleKey: t("post15section5"),
          contentKeys: [t("post15conteudo8")],
        },
        {
          titleKey: t("post15section6"),
          contentKeys: [t("post15conteudo9")],
        },
        {
          titleKey: t("post15section7"),
          contentKeys: [t("post15conteudo10")],
        },
        {
          titleKey: t("post15section8"),
          contentKeys: [t("post15conteudo11")],
        },
        {
          titleKey: t("post15section9"),
          contentKeys: [t("post15conteudo12")],
        },
      ],
    },
    {
      _id: "16",
      titleKey: t("post16Title"), // Chave para tradução
      contentKeys: [t("post16subtitle")], // Chaves para tradução
      postType: "procedimentos",
      photo: imgPost16,
      publishDate: "2025-01-02",
      relatedPostsIds: ["13", "14", "15"],
      sections: [
        {
          contentKeys: [t("post16conteudo1"), t("post16conteudo2")], // Chaves para tradução
        },
        {
          titleKey: t("post16section1"),
          subtitleKey: t("post16subtitle2"),
          contentKeys: [
            t("post16conteudo3"),
            t("post16conteudo4"),
            t("post16conteudo5"),
            t("post16conteudo6"),
            t("post16conteudo7"),
          ],
        },
        {
          titleKey: t("post16section2"),
          subtitleKey: t("post16subtitle3"),
          contentKeys: [
            t("post16conteudo8"),
            t("post16conteudo9"),
            t("post16conteudo10"),
          ],
        },
        {
          subtitleKey: t("post16subtitle4"),
          contentKeys: [
            t("post16conteudo11"),
            t("post16conteudo12"),
            t("post16conteudo13"),
          ],
        },
        {
          subtitleKey: t("post16subtitle5"),
          contentKeys: [t("post16conteudo14"), t("post16conteudo15")],
        },
        {
          subtitleKey: t("post16subtitle6"),
          contentKeys: [
            t("post16conteudo16"),
            t("post16conteudo17"),
            t("post16conteudo18"),
          ],
        },
        {
          titleKey: t("post16section3"),
          contentKeys: [
            t("post16conteudo19"),
            t("post16conteudo20"),
            t("post16conteudo21"),
          ],
        },
        {
          titleKey: t("post16section4"),
          contentKeys: [
            t("post16conteudo22"),
            t("post16conteudo23"),
            t("post16conteudo24"),
            t("post16conteudo25"),
          ],
        },

        {
          titleKey: t("post16section5"),
          contentKeys: [t("post16conteudo26"), t("post16conteudo27")],
        },
      ],
    },
    {
      _id: "17",
      titleKey: t("post17Title"), // Chave para tradução
      contentKeys: [t("post17subtitle")], // Chaves para tradução
      postType: "procedimentos",
      photo: imgPost17,
      publishDate: "2025-01-10",
      relatedPostsIds: ["14", "15", "16"],
      sections: [
        {
          contentKeys: [t("post17conteudo1")], // Chaves para tradução
        },
        {
          titleKey: t("post17section1"),

          contentKeys: [
            t("post17conteudo2"),
            t("post17conteudo3"),
            t("post17conteudo4"),
            t("post17conteudo5"),
            t("post17conteudo6"),
          ],
        },
        {
          titleKey: t("post17section2"),

          contentKeys: [
            t("post17conteudo7"),
            t("post17conteudo8"),
            t("post17conteudo9"),
            t("post17conteudo10"),
            t("post17conteudo11"),
          ],
        },
        {
          titleKey: t("post17section3"),

          contentKeys: [
            t("post17conteudo12"),
            t("post17conteudo13"),
            t("post17conteudo14"),
            t("post17conteudo15"),
            t("post17conteudo16"),
          ],
        },
        {
          titleKey: t("post17section4"),

          contentKeys: [
            t("post17conteudo17"),
            t("post17conteudo18"),
            t("post17conteudo19"),
            t("post17conteudo20"),
            t("post17conteudo21"),
          ],
        },
        {
          titleKey: t("post17section5"),

          contentKeys: [
            t("post17conteudo22"),
            t("post17conteudo23"),
            t("post17conteudo24"),
            t("post17conteudo25"),
            t("post17conteudo26"),
          ],
        },
        {
          titleKey: t("post17section6"),
          subtitleKey: t("post17subtitle1"),
          contentKeys: [
            t("post17conteudo27"),
            t("post17conteudo28"),
            t("post17conteudo29"),
            t("post17conteudo30"),
          ],
        },
        {
          titleKey: t("post17section7"),

          contentKeys: [t("post17conteudo31"), t("post17conteudo32")],
        },
      ],
    },
    {
      _id: "18",
      titleKey: t("post18Title"), // Chave para tradução
      contentKeys: [t("post18subtitle")], // Chaves para tradução
      postType: "procedimentos",
      photo: imgPost18,
      publishDate: "2025-01-19",
      relatedPostsIds: ["15", "16", "17"],
      sections: [
        {
          contentKeys: [t("post18conteudo1")], // Chaves para tradução
        },
        {
          subtitleKey: t("post18subtitle1"),

          contentKeys: [
            t("post18conteudo2"),
            t("post18conteudo3"),
            t("post18conteudo4"),
            t("post18conteudo5"),
            t("post18conteudo6"),
          ],
        },
        {
          titleKey: t("post18subtitle2"),

          contentKeys: [
            t("post18conteudo7"),
            t("post18conteudo8"),
            t("post18conteudo9"),
            t("post18conteudo10"),
            t("post18conteudo11"),
          ],
        },
        {
          titleKey: t("post18section1"),

          contentKeys: [t("post18conteudo12"), t("post18conteudo13")],
        },
      ],
    },
    {
      _id: "19",
      titleKey: t("post19Title"), // Chave para tradução

      postType: "procedimentos",
      photo: imgPost19,
      publishDate: "2025-01-21",
      relatedPostsIds: ["16", "17", "18"],
      sections: [
        {
          contentKeys: [t("post19conteudo1"), t("post19conteudo2")], // Chaves para tradução
        },
        {
          titleKey: t("post19section1"),
          subtitleKey: t("post19subtitle1"),

          contentKeys: [
            t("post19conteudo3"),
            t("post19conteudo4"),
            t("post19conteudo5"),
            t("post19conteudo6"),
          ],
        },
        {
          titleKey: t("post19section2"),
          subtitleKey: t("post19subtitle2"),

          contentKeys: [
            t("post19conteudo7"),
            t("post19conteudo8"),
            t("post19conteudo9"),
            t("post19conteudo10"),
          ],
        },
        {
          titleKey: t("post19section3"),
          subtitleKey: t("post19subtitle3"),

          contentKeys: [
            t("post19conteudo11"),
            t("post19conteudo12"),
            t("post19conteudo13"),
            t("post19conteudo14"),
          ],
        },
        {
          titleKey: t("post19section4"),
          subtitleKey: t("post19subtitle4"),

          contentKeys: [
            t("post19conteudo19"),
            t("post19conteudo20"),
            t("post19conteudo21"),
            t("post19conteudo22"),
          ],
        },
        {
          titleKey: t("post19section5"),

          contentKeys: [t("post19conteudo23"), t("post19conteudo24")],
        },
      ],
    },
    {
      _id: "20",
      titleKey: t("post20Title"), // Chave para tradução
      subtitleKey: t("post20Subtitle"), // Chave para
      postType: "noticias",
      photo: imgPost20,
      publishDate: "2025-01-27",
      relatedPostsIds: ["19", "17", "18"],
      sections: [
        {
          contentKeys: [t("post20conteudo1")], // Chaves para tradução
        },
        {
          titleKey: t("post20section1"),
          subtitleKey: t("post20subtitle1"),

          contentKeys: [
            t("post20conteudo2"),
            t("post20conteudo3"),
            t("post29conteudo4"),
          ],
        },
        {
          titleKey: t("post20section2"),
          subtitleKey: t("post20subtitle2"),
          contentKeys: [
            t("post20conteudo5"),
            t("post20conteudo6"),
            t("post29conteudo7"),
          ],
        },
        {
          titleKey: t("post20section2"),
          subtitleKey: t("post20subtitle2"),
          contentKeys: [
            t("post20conteudo5"),
            t("post20conteudo6"),
            t("post29conteudo7"),
          ],
        },
        {
          titleKey: t("post20section3"),
          subtitleKey: t("post20subtitle3"),
          contentKeys: [],
        },
        {
          titleKey: t("post20section4"),
          subtitleKey: t("post20subtitle4"),
          contentKeys: [],
        },
      ],
    },
  ];
};

export default useDataBlog;
